import Signup from "./pages/auth/Signup";
import AuthTabs from "./pages/auth/AuthTabs";
import Signin from "./pages/auth/Signin";
import NotFound from "./pages/NotFound";
import HomePage from "./components/HomePage";
import PasswordChange from "./pages/settings/PasswordChange";
import PageAnimeAllProjects from "./pages/pages/Page-Anime-All-Projects";
import TabLiked from "./pages/pages/liked/tabLiked";
import TabBasket from "./pages/pages/basket/TabBasket";
import Guide from "./pages/payment/Guide";
import Client from "./pages/client/Client";
import Navbar from "./components/navbar/Navbar";
import QPayScreen from "./pages/payment/QPayScreen";
import PagePopular from "./pages/pages/Page-Popular";
import MovieModal from "./components/modal/MovieModal";
import {createBrowserRouter} from "react-router-dom";
import Footer from "./components/common/footer/footer";
import React from "react";
import HeaderGuest from "./pages/public/HeaderGuest";
import FooterGuest from "./pages/public/FooterGuest";
import Player from "./pages/player/Player";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import PageAnimeLatestEp from "./pages/pages/Page-Anime-Latest-Ep";
import PageAnimeOngoing from "./pages/pages/Page-Anime-Ongoing";
import PagesAuthor from "./pages/pages/Pages-Author";
import Profile from "./pages/profile/Profile";
import ProfileEdit from "./pages/profile/Profile-edit";
import PageAnimeCompleted from "./pages/pages/Page-Anime-Completed";
import PageAnimeMovie from "./pages/pages/Page-Anime-Movie";
import PageMangaAllProjects from "./pages/pages/Page-Manga-All-Projects";
import PageMangaCompleted from "./pages/pages/Page-Manga-Completed";
import PageMangaOngoing from "./pages/pages/Page-Manga-Ongoing";
import PageOnlyNetflix from "./pages/pages/Page-Only-Netflix";
import PageOnlyMoviesAll from "./pages/pages/Page-Only-Movies-All";
import PageOnlyMovies from "./pages/pages/Page-Only-Movies";
import Transactions from "./pages/payment/Transactions";
import PageAllProjectsCompleted from "./pages/pages/Page-All-Projects-Completed";
import Manga from "./pages/manga/Manga";
import MangaPlayer from "./pages/manga/MangaPlayer";
import Recovery from "./pages/auth/Recovery";
import Otp from "./pages/auth/Otp";
import GuideGuest from "./pages/public/GuideGuest";
import QpayGuest from "./pages/public/QpayGuest";
import PageWatched from "./pages/pages/Page-Watched";
import NavbarCommon from "./components/navbar/Navbar-Common";
import ProfileImage from "./components/pages/profile/ProfileImage";
import PaymentBanks from "./pages/payment/PaymentBanks";
import PageOnlyMoviesSeries from "./pages/pages/Page-Only-MovieSeries";

export const userRoutes = createBrowserRouter(
    [
        {
            path: "",
            element: (
                <>
                    <ProtectedRoutes/>
                </>
            ),
            children: [
                {
                    path: "/",
                    errorElement: <NotFound/>,
                    element: (
                        <>
                            <Navbar/>
                            <HomePage/>
                            {/*<Footer/>*/}
                        </>
                    ),
                }, {
                    path: "/home",
                    element: (
                        <>
                            <Navbar/>
                            <HomePage/>
                            <Footer/>
                        </>
                    ),
                },
                {
                    path: "/client",
                    element: (
                        <>
                            <NavbarCommon/>
                            <Client/>
                            <Footer/>
                        </>

                    ),
                },
                {
                    path: "/transactions",
                    element: (
                        <>
                            <NavbarCommon/>
                            <Transactions/>
                            <Footer/>
                        </>

                    ),
                },
                {
                    path: "/profile",
                    element: (
                        <>
                            <NavbarCommon/>
                            <Profile/>
                            <Footer/>
                        </>
                    )
                    ,
                },
                {
                    path: "/profile-edit",
                    element: (
                        <>
                            <NavbarCommon/>
                            <ProfileEdit/>
                            <Footer/>
                        </>
                    ),
                },
                {
                    path: "/profile-image",
                    element: (
                        <>
                            <NavbarCommon/>
                            <ProfileImage/>
                        </>
                    ),
                },
                {
                    path: "/player/episode/:episodeId",
                    element: (
                        <>
                            <Player/>
                        </>

                    ),
                },
                {
                    path: "/password-change",
                    element:
                        (
                            <>
                                <NavbarCommon/>
                                <PasswordChange/>
                                <Footer/>
                            </>
                        ),
                },
                {
                    path: "/qpay",
                    element: (
                        <>
                            <NavbarCommon/>
                            <QPayScreen/>
                            <Footer/>
                        </>
                    ),
                },
                {
                    path: "/paymentByBanks/:id",
                    element: (
                        <>
                            <NavbarCommon/>
                            <PaymentBanks/>
                            <Footer/>
                        </>
                    ),
                },


                {
                    path: "/guide",
                    element: (
                        <>
                            <NavbarCommon/>
                            <Guide/>
                            <Footer/>
                        </>
                    ),
                },
                {
                    path: "/popular",
                    element: (
                        <>
                            <NavbarCommon/>
                            <PagePopular/>
                            <Footer/>
                        </>
                    ),
                },

                {
                    path: "/all-projects-completed",
                    element: (
                        <>
                            <NavbarCommon/>
                            <PageAllProjectsCompleted/>
                            <Footer/>
                        </>
                    ),
                },
                {
                    path: "/all-projects",
                    element: (
                        <>
                            <NavbarCommon/>
                            <PageAnimeAllProjects/>
                            <Footer/>
                        </>
                    ),
                },
                {
                    path: "/openModal/:id",
                    element: (
                        <>
                            <NavbarCommon/>
                            <MovieModal/>
                            <Footer/>
                        </>
                    ),
                },
                /*{
                    path: "/liked",
                    element: (
                        <>
                            <NavbarCommon/>
                            <TabLiked/>
                            <Footer/>
                        </>
                    ),
                },*/
                {
                    path: "/watched",
                    element: (
                        <>
                            <NavbarCommon/>
                            <PageWatched/>
                            <Footer/>

                        </>

                    ),
                },
                {
                    path: "/saved",
                    element: (
                        <>
                            <NavbarCommon/>
                            <TabBasket/>
                            <Footer/>
                        </>
                    ),
                },

                {
                    path: "/latest-episodes",
                    element:
                        <>
                            <NavbarCommon/>
                            <PageAnimeLatestEp/>
                            <Footer/>
                        </>
                    ,
                },
                {
                    path: "/anime-ongoing",
                    element:
                        <>
                            <NavbarCommon/>
                            <PageAnimeOngoing/>
                            <Footer/>
                        </>
                    ,
                },
                {
                    path: "/anime-completed",
                    element:
                        <>
                            <NavbarCommon/>
                            <PageAnimeCompleted/>
                            <Footer/>
                        </>
                    ,
                },
                {
                    path: "/anime-movies",
                    element:
                        <>
                            <NavbarCommon/>
                            <PageAnimeMovie/>
                            <Footer/>
                        </>
                    ,
                },
                {
                    path: "/manga-projects",
                    element:
                        <>
                            <NavbarCommon/>
                            <PageMangaAllProjects/>
                            <Footer/>
                        </>
                    ,
                },
                {
                    path: "/manga-completed",
                    element:
                        <>
                            <NavbarCommon/>
                            <PageMangaCompleted/>
                            <Footer/>
                        </>
                    ,
                },
                {
                    path: "/manga-ongoing",
                    element:
                        <>
                            <NavbarCommon/>
                            <PageMangaOngoing/>
                            <Footer/>
                        </>
                    ,
                },
                {
                    path: "/author-collections/:id",
                    element:
                        <>
                            <NavbarCommon/>
                            <PagesAuthor/>
                            <Footer/>
                        </>
                    ,
                },
                {
                    path: "/netflix",
                    element:
                        <>
                            <NavbarCommon/>
                            <PageOnlyNetflix/>
                            <Footer/>
                        </>
                    ,
                },
                {
                    path: "/all-movies",
                    element:
                        <>
                            <NavbarCommon/>
                            <PageOnlyMoviesAll/>
                            <Footer/>
                        </>
                    ,
                },
                {
                    path: "/movie-series",
                    element:
                        <>
                            <NavbarCommon/>
                            <PageOnlyMoviesSeries/>
                            <Footer/>
                        </>
                    ,
                },
                {
                    path: "/movies",
                    element:
                        <>
                            <NavbarCommon/>
                            <PageOnlyMovies/>
                            <Footer/>
                        </>
                    ,
                },
                {
                    path: "/manga/:id",
                    element: (
                        <>
                            <NavbarCommon/>
                            <Manga/>
                            <Footer/>
                        </>
                    ),
                },
                {
                    path: "/manga/:mangaId/chapter/:chapterId",
                    element: (
                        <>
                            <NavbarCommon/>
                            <MangaPlayer/>
                            <Footer/>
                        </>
                    ),
                },
                {
                    path: "*",
                    element: (
                        <>
                            <NavbarCommon/>
                            <NotFound/>
                            <Footer/>
                        </>
                    ),
                },
            ]
        },
        {
            path: "/",
            element:
                <>
                    <HeaderGuest/>
                    <AuthTabs/>
                    <FooterGuest/>
                </>
            ,
        },
        {
            path: "/auth",
            element:
                <>
                    <HeaderGuest/>
                    <AuthTabs/>
                    <FooterGuest/>
                </>
            ,
        },
        {
            path: "/signup",
            element: <Signup/>,
        },
        {
            path: "/signin",
            element: <Signin/>,
        },
        {
            path: "/recovery",
            element:
                <>
                    <HeaderGuest/>
                    <Recovery/>
                    <FooterGuest/>
                </>
                    ,
        },
        {
            path: "/otp",
            element:
                <>
                    <HeaderGuest/>
                    <Otp/>
                    <FooterGuest/>
                </>
            ,
        },
        {
            path: "/guide/public",
            element:
                <>
                    <HeaderGuest/>
                    <GuideGuest/>
                    <FooterGuest/>
                </>
            ,
        },
        {
            path: "/payment/public",
            element:
                <>
                    <HeaderGuest/>
                    <QpayGuest/>
                    <FooterGuest/>
                </>
            ,
        },
        {
            path: "/auth",
            element:
                <>
                    <HeaderGuest/>
                    <AuthTabs/>
                    <FooterGuest/>
                </>
            ,
        },
    ],
)
