import React from 'react'
import Star from "../icons/Star";

export default function CardCommon({item, onClick}) {

    if (item.movie){
        return (
            <>
                <div className={"card-common"} >
                    <div className={"img" } onMouseUp={onClick} onTouchEnd={onClick}>
                        <img src={`https://www.mnfansubs.net/resource/${item.movie.image?.name}.${item.movie?.image?.ext}`} />
                    </div>
                    <div className={"about"} >
                        {
                            item.movie.score &&
                            <div className={"rating"}>
                                <Star stroke={"#FF6600"}/>
                                <span>{item.score}</span>
                            </div>
                        }
                        <div className={"title"}>
                            <a>{item.movie.name }</a>
                        </div>
                        <div className={"flex gap-1"}>
                            <div>
                                <span>{item.movie.year}</span>
                            </div>
                            {
                                item.movie.totalNumber &&
                                <div>
                                    <span>• {item.movie.totalNumber} анги </span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        )

    }else if (item){
        return (
            <>
                <>
                    <div className={"card-common"}>
                        <div className={"img"} onMouseUp={onClick} onTouchEnd={onClick}>
                            <img src={`https://www.mnfansubs.net/resource/${item.image?.name}_s.${item?.image?.ext}`} />
                        </div>
                        <div className={"about"}>
                            {
                                item.score &&
                                <div className={"rating"}>
                                    <Star stroke={"#FF6600"}/>
                                    <span>{item.score}</span>
                                </div>

                            }
                            <div className={"title"}>
                                <a>{item.name}</a>
                            </div>
                            <div className={"flex gap-1"}>
                                <div>
                                    <span>{item.year}</span>
                                </div>
                                {
                                    item.totalNumber &&
                                    <div>
                                        <span>• {item.totalNumber} анги</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </>


            </>
        )
    } return null;


}
