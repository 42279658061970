import React, {useEffect, useRef, useState} from "react";
import {get} from "sodon-http/API";
import {API_URL} from "../../constans/API_URL";
import {useNavigate, useParams} from "react-router-dom";
import PlayerSection from "./PlayerSection";
// import ReactSplayer from "../../components/splayer/ReactSplayer";
import ReactSplayer from "@yormungandr/react-splayer";
// import ReactSplayer from "@yormungandr/react-splayer";

export default function Player() {

    const navigate = useNavigate();
    const {episodeId} = useParams();
    const [movieId, setMovieId] = useState(null);
    const [movieType, setMovieType] = useState(null);
    const [currentEpisode, setCurrentEpisode] = useState(null);
    const [selectedEpisode, setSelectedEpisode] = useState(null);
    const [id, setId] = useState(episodeId);

    const hasNextEpisodeRef = useRef(false);
    const playerRef = useRef(null);

    const [isPlayerSectionVisible, setIsPlayerSectionVisible] = useState(false);
    const [videoQualities, setVideoQualities] = useState([{label: null, type: null, src: ''}]);

    const [nextEpisode, setNextEpisode] = useState(null);

    useEffect(() => {
        if (playerRef.current) {
            if (isPlayerSectionVisible)
                playerRef.current._pauseVideo();
            else
                playerRef.current._playVideo();
        }
    }, [isPlayerSectionVisible])

    useEffect(() => {
        getEpisodeQuality(id);
        getMovieData();
        fetchNextEpisodeData();
    }, [id]);

    useEffect(() => {
        if (selectedEpisode) {
            setId(selectedEpisode.id);
            setCurrentEpisode(selectedEpisode);
            setIsPlayerSectionVisible(false);
            getEpisodeQuality(selectedEpisode.id);
            getMovieData();
        }
    }, [selectedEpisode]);

    useEffect(() => {

    }, [videoQualities])

    useEffect(() => {
        console.log("playerRef.current", playerRef.current);
    }, [playerRef.current]);


    const getMovieData = async () => {
        try {
            const response = await get(`${API_URL}/api/movie/episode/${id}`);
            if ((await response).success) {
                setMovieId((await response).payload.movie.id)
                setMovieType((await response).payload.movie.type.alias);
            }
        } catch (e) {

        }
    }


    const getEpisodeQuality = async (id) => {
        try {
            const response = await get(`${API_URL}/api/movie/episode/${id}`);
            if (response.success) {
                setCurrentEpisode(response.payload);
                const newArr = [];
                if (response.payload.video480) {
                    newArr.push({
                        src: `${API_URL}/movie/episode/${id}/video/480/url`,
                        type: "video/mp4",
                        label: '480p',
                    });
                }
                if (response.payload.video720) {
                    newArr.push({
                        src: `${API_URL}/movie/episode/${id}/video/720/url`,
                        type: "video/mp4",
                        label: "720"
                    });
                }
                if (response.payload.video1080) {
                    newArr.push({
                        src: `${API_URL}/movie/episode/${id}/video/1080/url`,
                        type: "video/mp4",
                        label: "1080"
                    });
                }
                setVideoQualities(newArr);
            }
        } catch (e) {

        }
    };

    const fetchNextEpisodeData = async () => {
        try {
            const next = await get(`${API_URL}/api/movie/episode/${episodeId}/next`);
            if (next) {
                if (next.payload.type !== "ERROR") {
                    // console.log("next", next.payload);
                    hasNextEpisodeRef.current = true;
                    await setNextEpisode(next.payload);
                } else {
                    // console.log("No next episode")
                    hasNextEpisodeRef.current = false;
                }
            }
        } catch (e) {
            console.warn("An error occurred while fetching next episode data: ", e);
        }
    }

    const handleClickOnEpisodes = () => {
        setIsPlayerSectionVisible(!isPlayerSectionVisible);
    };

    const handleClickOnNextEpisode = () => {
        if (nextEpisode) {
            if (nextEpisode.video480) {
                getEpisodeQuality(nextEpisode.id);
            }
        }
    }


    if (currentEpisode) {
        return (
            <div style={{display: "flex", flexFlow: "column", flex: 1}}>
                <ReactSplayer
                    ref={playerRef}
                    backNavigation={() => navigate(-1)}
                    sources={videoQualities}
                    onClickEpisodes={handleClickOnEpisodes}
                    onClickNextEpisode={handleClickOnNextEpisode}
                    loadstart={e => console.log("loadstart", e)}
                    isSeries={movieType && movieType === "anime"}
                    hasNextEpisode={hasNextEpisodeRef?.current}
                    error={(e) => {
                        console.log("Error while requesting for the media: ", e)
                    }}
                >
                    <div style={{position: "absolute", right: 0, zIndex: 0, pointerEvents: "none"}}>
                        <div className="drawer-container">
                            <div className={`player-section-drawer ${isPlayerSectionVisible ? 'open' : ''}`}>
                                {movieId && (
                                    <PlayerSection
                                        close={handleClickOnEpisodes}
                                        movieId={movieId}
                                        currentEpisode={currentEpisode}
                                        pressedEpisode={item => setSelectedEpisode(item)}
                                    />
                                )}
                            </div>
                            {isPlayerSectionVisible && <div className="backdrop" onClick={handleClickOnEpisodes}></div>}
                        </div>
                    </div>
                </ReactSplayer>

            </div>
        )
    }
}
