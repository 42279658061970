import React, {useEffect, useState} from 'react'
import BookmarkAdd from "../icons/Bookmark-add";
import BookmarkActive from "../icons/BookmarkActive";
import Bookmark from "../icons/Bookmark";
import Heart from "../icons/Heart";
import Star from "../icons/Star";
import BookmarkDouble from "../icons/BookmarkDouble";
import {get, postJson} from "sodon-http/API";
import {API_URL} from "../../constans/API_URL";
import CustomAlert from "../common/CustomAlert";
import BookmarkMinus from "../icons/BookmarkMinus";
import {Link} from "react-router-dom";

export default function CardPopularList({item, onClick}) {

    const [isLoading, setIsLoading] = useState(false)

    const [checkSaved, setCheckSaved] = useState(false)
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertMessage, setAlertMessage] = useState("");

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const response = await get(`${API_URL}/api/client/user/savedItem/check?module=movie&moduleItemId=${item.id}`);
                if (response.success) {
                    const data = response.payload;
                    if (typeof data === 'boolean') {
                        setCheckSaved(data);
                    }
                }
            } catch (error) {

            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [item.id]);




    const handleSaveItem = async () => {
        setAlertVisible(true);
        try {
            const form = {
                module: 'MOVIE',
                moduleItemId: item.id,
                moduleItemName: item.name,
                moduleItemDesc: item.description,
                moduleItemImage: `${item.image?.name}.${item.image?.ext}`
            };
            // Make the POST request
            const response = await postJson(`${API_URL}/api/client/user/savedItem/saveOrDelete`, form);
            if (response.status === 200 && response.success) {
                setCheckSaved(!checkSaved);
                setAlertType(!checkSaved === true ? 'success' : 'warning');
                setAlertMessage(!checkSaved === true ? 'Амжилттай хадгалагдлаа ' : 'Амжилттай устгагдлаа')
                setAlertVisible(true);
                setTimeout(() => {
                    setAlertVisible(false);
                }, 2000);
            }
        } catch (error) {
            console.error("Error saving item:", error);
            alert('Алдаа', error);
        }
    };






    return (
        <div className={"card-popular-list"}>
            <CustomAlert isVisible={alertVisible} type={alertType} message={alertMessage}/>
           <div className={"count"}>
                <span>
                    {item.id}
                </span>
           </div>
            <button onMouseUp={handleSaveItem}>
                {
                    checkSaved ===false &&
                    <div className={"svg"}>
                        <BookmarkDouble width={34}  height={42} stroke={"#00b3ff"} color={"#4A4A4A"}/>
                    </div>
                }
                {
                    checkSaved ===true &&
                    <div className={"svg"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="43" viewBox="0 0 34 43" fill="#FFF">
                            <path d="M16.8016 34.7042L0.5 41.7523V1.01318H33.5V41.7523L17.1984 34.7042L17 34.6185L16.8016 34.7042Z" fill="#199628" stroke="#fff"/>
                            <g transform="translate(7 8.5)">
                                <path d="M4.16666 10.5133H15.8333" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                        </svg>

                    </div>
                }
            </button>
            <div onMouseUp={onClick} onTouchEnd={onClick} className={"about"}>
                <div className={"title"}>
                    <a>
                        {item.name}
                    </a>
                </div>
                <div  className={"flex items-center gap-1"}>
                    {
                        item.rating &&
                        <>
                            <div>
                                <Star stroke={"#A7A5A5"} />
                            </div>
                            <span>
                            4.7(2k)
                            </span>
                        </>
                    }
                    {
                        item.totalNumber &&
                        <span>
                        {item.totalNumber} анги
                        </span>

                    }
                </div>
            </div>

        </div>
    )
}
