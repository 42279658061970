import React, {useState} from 'react'
import Copy from "../../components/icons/Copy";
import Qpay from "../../components/icons/Qpay";
import {Link} from "react-router-dom";
import CustomAlert from "../../components/common/CustomAlert";
import ArrowLeft from "../../components/icons/ArrowLeft";

export default function GuideGuest() {


    const [alertVisible, setAlertVisible] = useState(false);
    const [message, setMessage] = useState("");


    let copyText1Next = true;

    const handleCopy = (text1, text2) => {
        const textToCopy = copyText1Next ? text1 : text2;

        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                setMessage(`${textToCopy}`)
                setAlertVisible(true);
                setTimeout(() => {
                    setAlertVisible(false);
                }, 2000);

                // Toggle the flag to copy the other text next time
                copyText1Next = !copyText1Next;
            })
            .catch(err => {
                // Error handling
                console.error('Failed to copy text: ', err);
            });
    };

    const name  = "ЭНХТӨР";
    const bank = "5302808442";


    return (
        <div className={"container-center"} style={{height: "70vh"}}>
            <CustomAlert isVisible={alertVisible} type={"success"} message={message}/>
            <div className={"guide"}>
                <div className={"header"}>
                    <Link to={"/auth"} className={"button-md button-primary"}>
                        <ArrowLeft/>
                    </Link>
                    <div>
                          <span>
                              Хандив өгөх заавар
                          </span>
                    </div>
                </div>
                <div className={"body"}>
                    <div className={"header"}>
                        <span>
                        Дансны мэдээлэл:
                        </span>
                    </div>
                    <div className={"about"}>
                        <div className={"grid grid-cols-2"}>
                            <div>
                                <span>
                                    Нэр:
                                </span>
                            </div>
                            <div className={"flex items-center justify-between"}>
                                <span >
                                    {name}
                                </span>
                                <button  onClick={() => handleCopy(name)}>
                                    <Copy/>
                                </button>
                            </div>
                        </div>
                        <div className={"grid grid-cols-2 "}>
                            <div>
                                <span>
                                Хаан банк:
                                </span>
                            </div>
                            <div className={"flex items-center justify-between"}>
                                <span>
                                    {bank}
                                </span>
                                <button onClick={() => handleCopy(bank)}>
                                    <Copy/>
                                </button>
                            </div>
                        </div>
                        <div className={"desc"}>
                            <span>
                                Манай багт хандивласан дүнгийн хэмжээнээс хамаарч таныг бид хандивлагч гишүүнээр бүртгэх болно. Ингэснээр та сайтын бүх хэсэгт хандах эрхтэй болох юм. Хандив илгээснээс хойш 0-9 цагийн дотор таны эрх нээгдэнэ. Эсвэл та доорх QPAY сонголтыг ашиглан эрхээ шууд нээлгэх боломжтой.Бидний үйл ажиллагааг дэмжсэнд баярлалаа.
                            </span>
                        </div>
                    </div>
                </div>
                <div className={"grid lg:grid-cols-3 mt-5 gap-2"}>
                    <Link to={"/payment/public"}>
                        <div className={"button-md button-primary"}>
                            <Qpay/>
                            Төлбөрийн мэдээлэл
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}
