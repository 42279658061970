import React, {} from "react";
import {Dialog, useMediaQuery, useTheme} from "@mui/material";
import Slide from "@mui/material/Slide";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} timeout={{
        enter: 300,
        exit: 100,
        appear: 300
    }}/>
})

function CustomPopup({open, close, children  }){

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));


    return(
        <>
            <Dialog
                open={open}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                onClose={close}
                TransitionComponent={Transition}
                fullScreen={fullScreen}
                maxWidth="md"
                scroll="body"
            >
                {open &&
                    <>
                        {
                            children
                        }
                     </>
                }
            </Dialog>

        </>

    )

}

export default CustomPopup
