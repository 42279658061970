import React from 'react'
import {Link} from "react-router-dom";
import {ReactSVG} from "react-svg";

export default function HeaderGuest() {

    return (
        <div className={"container"}>
            <div className={"header"}>
                <div className={"logo"}>
                    <Link to="/auth" className="" >
                        <ReactSVG src="icons/logo.svg"/>
                    </Link>
                </div>
            </div>
        </div>
    )
}
