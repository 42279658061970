import React, {useEffect} from "react"
import {useTokenStore, useUserStore} from "../constans/StoreUserData";
import {Outlet} from "react-router-dom";
import {get} from "sodon-http/API";
import {API_URL} from "../constans/API_URL";
import AuthTabs from "../pages/auth/AuthTabs";
import HeaderGuest from "../pages/public/HeaderGuest";


const ProtectedRoutes = () => {
    const {user, setUser, clearUser} = useUserStore();
    const {clearToken} = useTokenStore();

    let isAuth = true;

    useEffect(() => {
        const fetchSignedUser = async () => {
            try {
                const response = await get(`${API_URL}/api/core/signedUser`);
                if (response.success) {
                    await setUser(response.payload);
                    isAuth = true;
                } else if (
                    response.status === 403 ||
                    response.status === 401 ||
                    response.status === 500
                ) {
                    await setUser(null);
                    await clearUser();
                    await clearToken();
                    isAuth = false;
                }
            } catch (e) {
                console.warn(`Protected route error: ${e}`);
            }
        }
        fetchSignedUser();
    }, [setUser]);


    if (!user) {
        return (
            <>
                <HeaderGuest/>
                <AuthTabs replace/>
            </>
        )
    }

    return <Outlet/>
}

export default ProtectedRoutes;
